<template>
    <div id="activityDetail">
        <component-education-navbar logoColor="white"></component-education-navbar>
        <img src="@/assets/images/education/activity_detail_bg.png" alt="" id="bg">
        <div class="content">
            <div>
                <div class="breadcrumbs">
                    <el-breadcrumb>
                        <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="index" :to="item.href">
                            {{ item.text }}
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="detail">
                    <h2>{{ isEn ? data.enTitle : data.cnTitle }}</h2>
                    <div>
                        <p>更新时间：{{ data.updateTime | formatDate }}</p>
                        <p>阅读次数：{{ data.readCounts }}</p>
                    </div>
                    <p class="context" v-html="isEn ? data.enContent : data.cnContent"></p>
                    <ul class="file-list" v-if="data && data.files.length > 0">
                        <li v-for="(item, index) in data.files" :key="index">
                            <div class="img" v-if="item.fileUrl.lastIndexOf('.png') !== -1 || item.fileUrl.lastIndexOf('.jpg') !== -1">
                                <img :src="staticPath + item.fileUrl" alt="">
                            </div>
                            <div class="video" v-if="item.fileUrl.lastIndexOf('.mp4') !== -1">
                                <video :src="staticPath + item.fileUrl" controls width="100%"></video>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="btns">
                    <span class="prev" @click="getOtherArticle('previous')">上一篇</span>
                    <button class="btn-back" @click="$router.go(-1)">返回列表</button>
                    <span class="next" @click="getOtherArticle('next')">下一篇</span>
                </div>
            </div>
        </div>
        <component-education-bottom></component-education-bottom>
    </div>
</template>
<script>
    import {urls, staticPath} from '@/lib/common'
    import ComponentEducationNavbar from '@/components/ComponentEducationNavbar'
    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    export default {
        name: "ActivityDetail",
        components: {
            ComponentEducationNavbar,
            ComponentEducationBottom
        },
        watch: {
            "$i18n.locale": function(val) {
                this.isEn = val === 'en' ? true : false;
            }
        },
        data() {
            return {
                staticPath: staticPath,
                breadcrumbs: [],
                data: '',
                isEn: false,
                articleId: '',
                navId: '',

            };
        },
        methods: {
            getBreadcrumb() {
                this.$http.get(urls.getBreadcrumb + this.navId).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        const result = res.data.data;
                        const homePage = {
                            text: this.isEn ? 'Home' : '首页',
                            pathName: 'Home',
                            disabled: false,
                            href: '/education?n=OTA%3D'
                        }
                        this.breadcrumbs = [
                            homePage,
                            {
                                text: this.isEn ? result[0].enName : result[0].cnName,
                                pathName: result[0].templateUrl,
                                disabled: false,
                                href: result[0].templateUrl
                            }
                        ]
                        if (result[1]) {
                            this.breadcrumbs.push({
                                text: this.isEn ? result[1].enName : result[1].cnName,
                                pathName: result[1].templateUrl,
                                disabled: true,
                                href: ''
                            })
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getDetail() {
                this.$http.get(urls.getArticleDetail + this.articleId).then(res => {
                    console.log(res);
                    if (res.data.status === 200) {
                        this.data = res.data.data;
                        console.log(this.data);
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            getOtherArticle(direction) {
                const params = {
                    articleId: this.articleId,
                    navigationId: this.navId
                }
                this.$http.get(urls.getSiblingsArticle + direction, {params}).then(res => {
                    console.log(res)
                    if (res.data.status === 200) {
                        if (res.data.data === null) {
                            let text = direction === 'previous' ? '没有上一篇了!~' : '没有下一篇了!~'
                            this.$message.warning(text)
                        } else {
                            this.data = res.data.data;
                            this.articleId = this.data.id;
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        mounted() {
            this.articleId = atob(decodeURIComponent(this.$route.params.id));
            this.navId = parseInt(atob(decodeURIComponent(this.$route.query.n)));
            console.log(this.articleId, this.navId)
            this.getBreadcrumb();
            this.getDetail();
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #activityDetail {
        min-width: 1380px;
        position: relative;
        .content {
            width: 100%;
            position: relative;
            top: -100px;
            >div {
                width: 70vw;
                margin: 0 auto;
                .breadcrumbs {
                    .el-breadcrumb {
                        font-size: 18px;
                    }
                }
                .detail {
                    >h2 {
                        font-size: 1.875vw;
                        margin: 2vw 0 1vw;
                    }
                    >div {
                        padding-bottom: 2vw;
                        margin-bottom: 2vw;
                        border-bottom: 1px solid #6ADCFF;
                        display: flex;
                        font-size: 14px;
                        >p {
                            margin-right: 1vw;
                        }
                    }
                    >p {
                        /*text-indent: 2em;*/
                        font-size: 0.83vw;
                        line-height: 200%;
                        text-align: center;
                    }
                    .file-list {
                        width: 70%;
                        margin: auto;
                        text-align: center;
                    }
                    /*img {*/
                    /*    display: block;*/
                    /*    width: 50%;*/
                    /*    margin: 1vw auto;*/
                    /*}*/
                }
                .btns {
                    text-align: center;
                    margin-top: 5vw;
                    font-size: 1vw;
                    span {
                        display: inline-block;
                        cursor: pointer;
                        width: 30%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-break: break-all;
                        white-space: nowrap;
                    }
                    .btn-back {
                        display: inline-block;
                        margin: 0 1vw;
                        padding: 0.7vw 2vw;
                        border-radius: 4vw;
                        border: 1px solid #02A5E6;
                        color: #02A5E6;
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
            @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #activityDetail {
            padding-top: 70px;
            background: url('../../assets/images/phone/top_bg.png') no-repeat right top;
            background-size:100% 70px;
            min-width: 320px;
            .content{
                top:0;
                & > div{
                    width: 94%;
                    .breadcrumbs{
                        .el-breadcrumb{
                            font-size: 14px;
                        }
                    }
                    .detail{
                        &>h2{
                            .fontFn(19);
                            .fontFn(32,line-height);
                            padding:4% 0;
                        }
                        &>div{
                            padding-bottom: 4%;
                            .fontFn(12);
                        }
                        &>p{
                            .fontFn(12);
                        }
                        @{deep} .context{
                            img{
                                width: 96% !important;
                                height: auto !important;
                                display: block;
                                margin:0 auto;
                            }

                            h1,p{
                                text-indent: 0;
                            }
                        }

                        .file-list{
                            width: 100%;
                        }
                    }

                    .btns{
                        .fontFn(14);
                    }
                }
            }

        }
    }
</style>